import React from "react"
import { MDBDataTable } from "mdbreact"
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap"

import "../Shoppers/datatables.scss"


const MerchantsList = ({
    data,
    option = [10, 20, 30, 40, 60, 80, 100, 200],
    default_entries = 10,
    displayEntries = true,
    searching = true,
    title = "Stores List",
    paging = true
}) => {

    return (
        <React.Fragment>
            {/* <div><h4>{{ title }}</h4></div> */}
            <Row>
                <Col className="col-12">
                    <Card>
                        <CardTitle className="bg-light border-bottom p-3 mb-0">
                            <i className="mdi mdi-account-group-outline font-18 mr-2"></i> {title}
                        </CardTitle>
                        <CardBody>
                            <MDBDataTable
                                entriesOptions={option}
                                responsive bordered data={data}
                                entries={default_entries}
                                displayEntries={displayEntries}
                                searching={searching}
                                infoLabel={["Showing", "to", "of", "records"]}
                                paging={paging}
                            />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    )
}

export default MerchantsList
