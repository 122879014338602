import $ from 'jquery'
import PropTypes from 'prop-types'
import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import {
	Container,
	Row,
	Col,
	Button,
	Modal,
	Form,
	FormGroup,
	Label,
	Input,
	Card,
	CardBody,
} from "reactstrap"

import { ToastContainer, toast } from "react-toastify";
import * as url from "../../helpers/url_helper"
import { API_URL } from "../../helpers/api_helper";
import PlatformList from "./HandlerList";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"
import 'react-toastify/dist/ReactToastify.css';

class StoreHandle extends Component {
	constructor(props) {
		super(props)
		this.state = {
			platform: [],
			loading: false,
			modal_standard_create: false,
			modal_standard_edit: false,
			modal_standard_token: false,
			accessToken: "",
			refreshToken: "",
			storeHandle: {},
			name: "",
			email: "",
			username: "",
			password: "",
			showPassword: false
		}

		this.create_store_handle = this.create_store_handle.bind(this);
		this.edit_store_handle = this.edit_store_handle.bind(this);
		this.delete_store_handle = this.delete_store_handle.bind(this);
	}

	notify_info = (message) => toast.info(`${message}`);
	notify_error = (message) => toast.error(`${message}`);
	notify_success = (message) => toast.success(`${message}`);

	getBearToken = () => {
		const BToken = localStorage.getItem("veeperAuthToken");
		return JSON.parse(BToken);
	}

	togglePasswordVisibility = () => {
		this.setState((prevShowPassword) => ({
			showPassword: !prevShowPassword.showPassword
		}));
	};

	async componentWillMount() {
		const BearerToken = this.getBearToken();
		$("#loadingIcon").text("Loading ...").css('color', 'red');

		try {
			const response = await fetch(`${API_URL}${url.STORE_HANDLE}`, {
				method: 'get',
				headers: {
					'Authorization': `${BearerToken}`,
					'Accept': 'application/json',
					'Content-Type': 'application/json'
				}
			});

			const result = await response.json()

			if (response.ok) {
				const platform_list = result.data.map(({ id, name, handle, hotdeals_handle, domain, store, is_active }, index) => ({
					...{ id, name, handle, hotdeals_handle, domain, store, is_active },
					sn: index + 1,
					handle: handle?.split(",").map((ele, i, arr) => (
						<React.Fragment key={i}>
							<br />
						</React.Fragment>
					)) || "",
					hahotdeals_handlendle: hotdeals_handle?.split(",").map((ele, i, arr) => (
						<React.Fragment key={i}>
							{ele},
							<br />
						</React.Fragment>
					)) || '',
					status: is_active ? (<span className="btn btn-outline-success">Yes</span>) : (<span className="btn btn-outline-danger">No</span>),
					action: (<span>
						<Button
							color="success"
							onClick={() => this.edit_store_handle(id, name, handle, hotdeals_handle, domain, store, is_active)}
							className="btn btn-primary waves-effect waves-light mt-1"
							data-toggle="modal"
							data-target="#myModal"
						> EDIT </Button>
						&nbsp;&nbsp;
						<Button
							color="danger"
							onClick={() => this.delete_store_handle(id)}
							className="btn btn-danger waves-effect waves-light mt-1"
							data-toggle="modal"
							data-target="#myModal"
						> DELETE </Button>
					</span>)
				}));
				const data = {
					columns: [
						{
							label: "SN",
							field: "sn",
							sort: "asc",
							width: 50,
						},
						{
							label: "Name",
							field: "name",
							sort: "asc",
							width: 150,
						},
						{
							label: "Coupert Links",
							field: "handle",
							sort: "asc",
							width: 270,
						},
						{
							label: "Hotdeals Links",
							field: "hotdeals_handle",
							sort: "asc",
							width: 270,
						},
						{
							label: "Domain URL",
							field: "domain",
							sort: "asc",
							width: 150,
						},
						{
							label: "myShopify URL",
							field: "store",
							sort: "asc",
							width: 150,
						},
						{
							label: "Auto BCL",
							field: "status",
							sort: "asc",
							width: 150,
						},
						{
							label: "Action",
							field: "action",
							sort: "disabled",
							width: 300,
						}
					],
					rows: platform_list
				}
				this.setState({ platform: data, loading: true });
				this.notify_success("Auto BCL List Loaded successfully");
			} else if (response.status === 400) {
				this.notify_error(`Bad Request: ${result.message || "Invalid data"}`);
			} else if (response.status === 500) {
				this.notify_error(`Server Error: ${result.message || "Something went wrong"}`);
			} else {
				this.notify_error(`Unexpected Error: ${response.status} - ${result.message || "Please try again"}`);
			}

		} catch (err) {
			this.notify_error("Auto BCL Deletion failed, Please check your network connection.");
		}
	}

	componentDidMount() {
		setTimeout(() => this.setState({ subscribemodal: false }), 2000);

	}

	create_store_handle = () => {
		// clear state fdata 
		this.setState(prevState => ({
			storeHandle: {},
			modal_standard_create: !prevState.modal_standard_create,
		}))
	}

	edit_store_handle = (id, name, handle, hotdeals_handle, domain, store, is_active) => {
		this.setState(prevState => ({
			storeHandle: {
				id,
				name,
				handle,
				hotdeals_handle,
				domain,
				store,
				is_active
			},
			modal_standard_edit: !prevState.modal_standard_edit,
		}))
	}

	delete_store_handle = async (id) => {
		let answer = window.confirm("Are you sure you want to delete this store handle?");
		if (!answer) {
			return;
		}
		try {
			const BearerToken = this.getBearToken();
			const response = await fetch(`${API_URL}${url.STORE_HANDLE}`, {
				method: 'delete',
				headers: {
					'Authorization': `${BearerToken}`,
					'Accept': 'application/json',
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({
					"id": id
				})
			});

			const resulst = await response.json()
			if (response.ok) {
				this.notify_success("Auto BCL Deleted successfully");
				window.location.reload();
			} else if (response.status === 400) {
				this.notify_error(`Bad Request: ${result.message || "Invalid data"}`);
			} else if (response.status === 500) {
				this.notify_error(`Server Error: ${result.message || "Something went wrong"}`);
			} else {
				this.notify_error(`Unexpected Error: ${response.status} - ${result.message || "Please try again"}`);
			}

		} catch (err) {
			this.notify_error("Auto BCL Deletion failed, Please check your internet connection");
		}
	}

	updateStoreHandle = async (e) => {
		e.preventDefault();
		try {
			const data = this.state;
			const { id, ...update_records } = data.storeHandle;
			const response = await fetch(`${API_URL}${url.STORE_HANDLE}`, {
				method: 'put',
				headers: {
					'Authorization': `${this.getBearToken()}`,
					'Accept': 'application/json',
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({
					id,
					update_records: {
						...update_records,
						is_active: Boolean(update_records.is_active)
					}
				})
			});

			const result = await response.json()
			if (response.ok) {
				this.notify_success("Auto BCL Updated successfully");
				await new Promise(r => setTimeout(r, 5000));
				window.location.reload();
			} else if (response.status === 400) {
				this.notify_error(`Bad Request: ${result.message || "Invalid data"}`);
			} else if (response.status === 500) {
				this.notify_error(`Server Error: ${result.message || "Something went wrong"}`);
			} else {
				this.notify_error(`Unexpected Error: ${response.status} - ${result.message || "Please try again"}`);
			}

		} catch (err) {
			this.notify_error("Auto BCL updated failed, Please check your internet connection");
		}

	}

	handlePlatformSubmit = async (e) => {
		e.preventDefault();
		const data = this.state;
		try {
			const response = await fetch(`${API_URL}${url.STORE_HANDLE}`, {
				method: 'post',
				headers: {
					'Authorization': `${this.getBearToken()}`,
					'Accept': 'application/json',
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({
					...data.storeHandle
				})
			});

			const result = await response.json();
			if (response.ok) {
				this.notify_success("Auto BCL Created successfully");
				await new Promise(r => setTimeout(r, 5000));
				window.location.reload();
			} else if (response.status === 400) {
				this.notify_error(`Bad Request: ${result.message || "Invalid data"}`);
			} else if (response.status === 500) {
				this.notify_error(`Server Error: ${result.message || "Something went wrong"}`);
			} else {
				this.notify_error(`Unexpected Error: ${response.status} - ${result.message || "Please try again"}`);
			}
		} catch (err) {
			this.notify_error("Auto BCL Creation failed, Please check your network connection.");
		}
	}

	handleInputChange = (e) => {
		const { name, value, checked, type } = e.target;
		console.log({ name, value })
		this.setState({
			storeHandle: {
				...this.state.storeHandle,
				[name]: type === "checkbox" ? checked : value
			}
		})
	}

	render() {
		const {
			loading,
			platform,
		} = this.state;

		return (
			<React.Fragment>
				<div className="page-content">
					<MetaTags>
						<title>Veeper | AP - Auto BCL</title>
					</MetaTags>
					<Container fluid>
						{/* Render Breadcrumb */}
						<Breadcrumbs
							title={this.props.t("Auto BCL")}
							breadcrumbItem={this.props.t("Auto BCL")}
						/>

						<ToastContainer />
						<Row>
							<div className="" style={{ "textAlign": "right", "paddingBottom": "10px" }}>
								<span className="loading"></span>
								<Button
									color="primary"
									className="btn btn-primary waves-effect waves-light"
									onClick={this.create_store_handle}
									data-toggle="modal"
									data-target="#myModal"
								>Create</Button>
							</div>
							<Col xl={12}>
								{loading ? <PlatformList customers={platform} /> : <p id="loadingIcon" style={{ "paddingLeft": "34px" }}>Loading ...</p>}
							</Col>

							<Modal
								isOpen={this.state.modal_standard_create}
								toggle={this.create_store_handle}
								size='lg'
							>
								<div className="modal-header">
									<h5 className="modal-title mt-0" id="myModalLabel">
										Create Auto BCL Info
									</h5>
									<button
										type="button"
										onClick={() =>
											this.setState({ modal_standard_create: false })
										}
										className="close"
										data-dismiss="modal"
										aria-label="Close"
									>
										<span aria-hidden="true">&times;</span>
									</button>
								</div>
								<div className="modal-body">
									<Card>
										<CardBody>
											<Form onSubmit={this.handlePlatformSubmit}>
												<FormGroup className="row mb-4">
													<Label
														htmlFor="horizontal-name-Input"
														className="col-sm-3 col-form-label"
													>
														Name
													</Label>
													<Col sm={8}>
														<Input
															type="text"
															name="name"
															placeholder="Name"
															className="form-control"
															id="horizontal-name-Input"
															value={this.state?.storeHandle?.name}
															onChange={this.handleInputChange}
														/>
													</Col>
												</FormGroup>
												<FormGroup className="row mb-4">
													<Label
														htmlFor="horizontal-handle-Input"
														className="col-sm-3 col-form-label"
													>
														Coupert Links
													</Label>
													<Col sm={8}>
														<Input
															type="textarea"
															name="handle"
															placeholder="Write coupert links with comma separator"
															className="form-control"
															id="horizontal-handle-Input"
															value={this.state?.storeHandle?.handle}
															onChange={this.handleInputChange}
															rows="5"
														/>
													</Col>
												</FormGroup>
												<FormGroup className="row mb-4">
													<Label
														htmlFor="horizontal-hotdeals-Input"
														className="col-sm-3 col-form-label"
													>
														Hotdeals Links
													</Label>
													<Col sm={8}>
														<Input
															type="textarea"
															name="hotdeals_handle"
															placeholder="Write hotdeals links with comma separator"
															className="form-control"
															id="horizontal-hotdeals-Input"
															value={this.state?.storeHandle?.hotdeals_handle}
															onChange={this.handleInputChange}
															rows="5"
														/>
													</Col>
												</FormGroup>
												<FormGroup className="row mb-4">
													<Label
														htmlFor="horizontal-domain-Input"
														className="col-sm-3 col-form-label"
													>
														Domain URL
													</Label>
													<Col sm={8}>
														<Input
															type="text"
															name="domain"
															placeholder="Domain"
															className="form-control"
															id="horizontal-domain-Input"
															value={this.state?.storeHandle?.domain}
															onChange={this.handleInputChange}
														/>
													</Col>
												</FormGroup>
												<FormGroup className="row mb-4">
													<Label
														htmlFor="horizontal-store-Input"
														className="col-sm-3 col-form-label"
													>
														myShopify URL
													</Label>
													<Col sm={8}>
														<Input
															type="text"
															name="store"
															placeholder="Store"
															className="form-control"
															id="horizontal-store-Input"
															value={this.state?.storeHandle?.store}
															onChange={this.handleInputChange}
															required
														/>
													</Col>
												</FormGroup>

												<FormGroup className="row mb-4">
													<Label
														htmlFor="horizontal-email-Input"
														className="col-sm-3 col-form-label"
													>
														Auto BCL
													</Label>
													<Col sm={8}>
														<Input
															type="checkbox"
															name="is_active"
															placeholder="Auto BCL"
															className="form-control"
															id="horizontal-is_active-Input"
															value={this.state?.storeHandle?.is_active}
															onChange={this.handleInputChange}
														/>
													</Col>
												</FormGroup>
												<FormGroup className="row justify-content-end">
													<Col sm={9}>
														<div>
															<Button
																type="submit"
																color="primary"
																className="w-md"
															>
																Submit
															</Button>
														</div>
													</Col>
												</FormGroup>
											</Form>
										</CardBody>
									</Card>
								</div>
							</Modal>

							<Modal
								isOpen={this.state.modal_standard_edit}
								toggle={this.edit_store_handle}
								size='lg'
							>
								<div className="modal-header">
									<h5 className="modal-title mt-0" id="myModalLabel">
										Edit Auto BCL Info
									</h5>
									<button
										type="button"
										onClick={() =>
											this.setState({ modal_standard_edit: false })
										}
										className="close"
										data-dismiss="modal"
										aria-label="Close"
									>
										<span aria-hidden="true">&times;</span>
									</button>
								</div>

								<div className="modal-body">
									<Card>
										<CardBody>
											<Form onSubmit={this.updateStoreHandle}>
												<FormGroup className="row mb-4">
													<Label
														htmlFor="horizontal-name-Input"
														className="col-sm-3 col-form-label"
													>
														Name
													</Label>
													<Col sm={8}>
														<Input
															type="text"
															name="name"
															placeholder="Auto BCL Name"
															className="form-control"
															id="horizontal-name-Input"
															value={this.state?.storeHandle?.name}
															onChange={this.handleInputChange}
														/>
													</Col>
												</FormGroup>
												<FormGroup className="row mb-4">
													<Label
														htmlFor="horizontal-handle-Input"
														className="col-sm-3 col-form-label"
													>
														Coupert Links
													</Label>
													<Col sm={8}>
														<Input
															type="textarea"
															name="handle"
															placeholder="Write coupert links with comma separator"
															className="form-control"
															id="horizontal-handle-Input"
															value={this.state?.storeHandle?.handle}
															onChange={this.handleInputChange}
															rows="5"
														/>
													</Col>
												</FormGroup>
												<FormGroup className="row mb-4">
													<Label
														htmlFor="horizontal-hotdeals-Input"
														className="col-sm-3 col-form-label"
													>
														Hotdeals Links
													</Label>
													<Col sm={8}>
														<Input
															type="textarea"
															name="hotdeals_handle"
															placeholder="Write hotdeals links with comma separator"
															className="form-control"
															id="horizontal-hotdeals-Input"
															value={this.state?.storeHandle?.hotdeals_handle}
															onChange={this.handleInputChange}
															rows="5"
														/>
													</Col>
												</FormGroup>

												<FormGroup className="row mb-4">
													<Label
														htmlFor="horizontal-domain-Input"
														className="col-sm-3 col-form-label"
													>
														Domain URL
													</Label>
													<Col sm={8}>
														<Input
															type="text"
															name="domain"
															placeholder="Domain"
															className="form-control"
															id="horizontal-domain-Input"
															value={this.state?.storeHandle?.domain}
															onChange={this.handleInputChange}
														/>
													</Col>
												</FormGroup>
												<FormGroup className="row mb-4">
													<Label
														htmlFor="horizontal-store-Input"
														className="col-sm-3 col-form-label"
													>
														myShopify URL
													</Label>
													<Col sm={8}>
														<Input
															type="text"
															name="store"
															placeholder="Store"
															className="form-control"
															id="horizontal-store-Input"
															value={this.state?.storeHandle?.store}
															readOnly
														/>
													</Col>
												</FormGroup>

												<FormGroup className="row mb-4">
													<Label
														htmlFor="horizontal-is_active-Input"
														className="col-sm-3 col-form-label"
													>
														Auto BCL
													</Label>
													<Col sm={8}>
														<Input
															type="checkbox"
															name="is_active"
															placeholder="Auto BCL"
															className="form-control"
															id="horizontal-is_active-Input"
															onChange={this.handleInputChange}
															checked={this.state?.storeHandle?.is_active}
														/>
													</Col>
												</FormGroup>

												<FormGroup className="row justify-content-end">
													<Col sm={9}>
														<div>
															<Button
																type="submit"
																color="primary"
																className="w-md"
															>
																Update
															</Button>
														</div>
													</Col>
												</FormGroup>
											</Form>
										</CardBody>
									</Card>
								</div>
							</Modal>
						</Row>
					</Container>
				</div>

			</React.Fragment >
		)

	}
}

StoreHandle.propTypes = {
	t: PropTypes.any
}

export default withTranslation()(StoreHandle)
